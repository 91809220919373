import { observer } from "mobx-react";
import { CloseIcon, SparklesIcon } from "outline-icons";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "sonner";
import { cn } from "@shared/editor/styles/utils";
import { UserPreference } from "@shared/types";
import DocumentModel from "~/models/Document";
import { FeedbackModalRouteState } from "~/hocs/withFeedbackModal/components/FeedbackModal/types";
import { AIDocumentQuestions } from "~/hocs/withFeedbackModal/constants";
import useCurrentUser from "~/hooks/useCurrentUser";
import useQuery from "~/hooks/useQuery";
import useStores from "~/hooks/useStores";
import { LoadingDots } from "./LoadingDots";

export interface NewDocumentDialogProps {
  onCreateEmptyDocument: () => void;
  activeCollectionId?: string;
}

export type CreateDocumentType = "default" | "ai";

const options: {
  value: CreateDocumentType;
  title: React.ReactNode;
  description: string;
}[] = [
  {
    value: "default",
    title: "Criar documento em branco",
    description: "Inicie um documento em branco para começar do zero.",
  },
  {
    value: "ai",
    title: (
      <span className="flex flex-row gap-2 items-center">
        Criar documento com Which AI <SparklesIcon size={20} />
      </span>
    ),
    description:
      "Escolha documentos existentes para a IA criar um novo documento com base neles.",
  },
];

function NewDocumentDialogComponent(props: NewDocumentDialogProps) {
  const { onCreateEmptyDocument, activeCollectionId } = props;
  const history = useHistory();
  const query = useQuery();
  const user = useCurrentUser();
  const match = useRouteMatch<{ id?: string }>();
  const { t } = useTranslation();
  const collectionId = match.params.id || query.get("collectionId");
  const { documents, whichai, dialogs } = useStores();
  const [visibleDocuments, setVisibleDocuments] = useState<DocumentModel[]>(
    documents.all
  );
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] =
    useState<CreateDocumentType>("default");
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [prompt, setPrompt] = useState<string>("");

  const handleCreateDocument = useCallback(
    async (documentIds: string[]) => {
      const parentDocumentId = query.get("parentDocumentId") ?? undefined;
      const parentDocument = parentDocumentId
        ? documents.get(parentDocumentId)
        : undefined;

      try {
        setLoading(true);
        const resp = await whichai.createDocumentWithAi({
          documentIds,
          prompt,
          docProps: {
            collectionId: activeCollectionId ?? collectionId,
            parentDocumentId,
            fullWidth:
              parentDocument?.fullWidth ||
              user.getPreference(UserPreference.FullWidthDocuments),
            templateId: query.get("templateId") ?? undefined,
          },
        });

        if (resp.ok) {
          dialogs.closeAllModals();

          const docId = resp.response?.data?.id;

          if (docId) {
            const feedbackData: FeedbackModalRouteState = {
              feedbackTitle:
                "O que você gostaria de melhorar ou adicionar nessa funcionalidade para uma experiência ainda melhor?",
              questions: AIDocumentQuestions,
              source: "ai-generate-document",
              referenceId: docId,
              summary: `Prompt: ${prompt}\n\nDocIds: ${documentIds.join(", ")}`,
            };
            history.replace(`/doc/${docId}`, { feedbackData });
          }

          toast.success(
            "Documento gerado com sucesso! Por favor, revise e ajuste conforme necessário antes de finalizá-lo e publicá-lo."
          );
        }
      } catch (err) {
        toast.error(t("Couldn’t create the document, try again?"));
        history.goBack();
      } finally {
        setLoading(false);
      }
    },
    [
      activeCollectionId,
      whichai,
      collectionId,
      dialogs,
      documents,
      history,
      prompt,
      query,
      t,
      user,
    ]
  );

  const handleFileSelect = useCallback((fileId: string) => {
    setSelectedFiles((prevSelected) =>
      prevSelected.includes(fileId)
        ? prevSelected.filter((fId) => fId !== fileId)
        : [...prevSelected, fileId]
    );
  }, []);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (ev) => {
      ev.preventDefault();
      if (!loading) {
        switch (selectedOption) {
          case "default":
            onCreateEmptyDocument();
            dialogs.closeAllModals();
            break;
          case "ai":
            void handleCreateDocument(selectedFiles);
            break;
        }
      }
    },
    [
      dialogs,
      handleCreateDocument,
      loading,
      onCreateEmptyDocument,
      selectedFiles,
      selectedOption,
    ]
  );

  useEffect(() => {
    if (selectedOption === "ai") {
      void documents.fetchAll();
    }
  }, [documents, selectedOption]);

  useEffect(() => {
    setPrompt("");
    setSelectedFiles([]);
    setSelectedOption("default");
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (!search) {
      setVisibleDocuments(documents.all);
    } else {
      timeoutId = setTimeout(() => {
        setVisibleDocuments(
          documents.all.filter((d) =>
            [d.title.toLowerCase(), d.collection?.name.toLowerCase()]
              .filter(Boolean)
              .some((p) => p!.includes(search.toLowerCase()))
          )
        );
      }, 400);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [documents.all, search]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-2">
        {options.map((option, idx) => (
          <div
            key={idx}
            className={cn(
              "flex flex-row gap-4 dark:border-[#21242F] border-gray-200 border-2 items-center p-4 rounded-md cursor-pointer hover:dark:bg-[#15181F] hover:bg-gray-100 active:dark:bg-zinc-800 active:bg-zinc-50",
              {
                "bg-gray-100 dark:bg-[#15181F]":
                  option.value === selectedOption,
              }
            )}
            onClick={(ev) => {
              ev.preventDefault();
              if (!loading) {
                setSelectedOption(option.value);
              }
            }}
          >
            <input
              type="radio"
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => setSelectedOption(option.value)}
              disabled={loading}
            />
            <div className="flex flex-col w-full gap-1 items-start">
              <span className="font-bold text-lg">{option.title}</span>
              <span className="text-gray-500 text-md">
                {option.description}
              </span>
            </div>
          </div>
        ))}
      </div>

      <div
        className={cn("flex flex-col transition-[height] duration-300", {
          hidden: selectedOption !== "ai",
        })}
      >
        <p className="font-medium mb-2 block">
          Escolha até 3 documentos que serão usados: ({selectedFiles.length}/3)
        </p>
        <div className="relative flex items-center mb-4">
          <input
            value={search}
            onChange={(ev) => setSearch(ev.currentTarget.value ?? "")}
            placeholder="Buscar"
            className="w-full p-4 bg-zinc-100 dark:bg-zinc-800 rounded-lg pr-8"
          />
          {search.length > 0 && (
            <button
              onClick={() => setSearch("")}
              className="absolute top-1/2 -translate-y-1/2 right-2 hover:bg-gray-200 active:bg-gray-300 hover:dark:bg-zinc-900 active:dark:bg-zinc-950 rounded-md p-1"
            >
              <CloseIcon size={24} />
            </button>
          )}
        </div>
        <div
          className={cn(
            "flex flex-col flex-1 border max-h-[35vh] overflow-y-auto border-gray-100 dark:border-gray-700 rounded-lg p-2 gap-2"
          )}
        >
          {visibleDocuments.map((doc) => {
            const disabled =
              selectedFiles.length >= 3 && !selectedFiles.includes(doc.id);
            return (
              <div
                key={doc.id}
                className={cn(
                  "flex flex-row gap-4 bg-zinc-100 dark:bg-zinc-800 rounded-md px-4 py-2 cursor-pointer ",
                  {
                    "[&_span]:dark:text-gray-700 [&_span]:text-gray-200 bg-transparent dark:bg-transparent cursor-not-allowed":
                      disabled,
                    "hover:dark:bg-[#15181F] hover:bg-gray-100": !disabled,
                  }
                )}
                onClick={(ev) => {
                  ev.stopPropagation();
                  handleFileSelect(doc.id);
                }}
              >
                <input
                  type="checkbox"
                  id={`file-${doc.id}`}
                  checked={selectedFiles.includes(doc.id)}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    handleFileSelect(doc.id);
                  }}
                  disabled={disabled}
                />
                <div className="flex flex-col">
                  <span className="font-bold">{doc.title}</span>
                  <span className="text-gray-500 text-sm">
                    Pasta: {doc.collection?.name}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div
        className={cn("mt-2", {
          hidden: selectedOption === "default",
        })}
      >
        <p className="font-medium mb-2 block">
          Faça uma breve descrição de como deseja que o documento final se
          pareça
        </p>
        <textarea
          value={prompt}
          disabled={loading}
          onChange={(ev) => setPrompt(ev.target.value ?? "")}
          className={cn(
            "w-full transition-[height] duration-300 h-30 p-4 bg-zinc-100 dark:bg-zinc-800 rounded-lg resize-none"
          )}
          required
        />
      </div>
      <button
        type="button"
        className={cn(
          "mt-4 px-4 py-2 text-white rounded-lg hover:bg-blue-700 transition bg-blue-600 active:bg-blue-600 disabled:bg-transparent disabled:text-gray-600 disabled:cursor-not-allowed"
        )}
        disabled={
          loading ||
          (selectedOption === "ai" &&
            (selectedFiles.length === 0 || prompt.length === 0))
        }
        onClick={handleSubmit}
      >
        {loading ? <LoadingDots /> : "Criar Novo Documento"}
      </button>
    </div>
  );
}

const NewDocumentDialog = observer(NewDocumentDialogComponent);

export { NewDocumentDialog };
