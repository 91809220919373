import { FeedbackQuestionData } from "./components/FeedbackQuestion/types";

const AIDocumentQuestions: FeedbackQuestionData[] = [
  {
    question: "Como avalia sua experiência ao criar um novo documento com IA?",
    options: [
      "Muito insatisfeito",
      "Insatisfeito",
      "Neutro",
      "Satisfeito",
      "Muito Satisfeito",
    ],
  },
  {
    question:
      "Quão relevante e preciso você considera o conteúdo gerado pela IA com base na sua própria base de documentos?",
    options: [
      "Muito irrelevante",
      "Irrelevante",
      "Neutro",
      "Relevante",
      "Muito Relevante",
    ],
  },
];

const CopilotQuestions: FeedbackQuestionData[] = [
  {
    question: "Como avalia sua experiência com a funcionalidade de Copilot?",
    options: [
      "Muito insatisfeito",
      "Insatisfeito",
      "Neutro",
      "Satisfeito",
      "Muito Satisfeito",
    ],
  },
  {
    question:
      "As sugestões fornecidades pelo copilot foram úteis e alinhadas às suas expectativas?",
    options: [
      "Muito irrelevante",
      "Irrelevante",
      "Neutro",
      "Relevante",
      "Muito Relevante",
    ],
  },
  {
    question:
      "A funcionalidade do Copilot contribuiu para melhorar sua eficiência na realização de tarefas?",
    options: [
      "Muito ineficiente",
      "Ineficiente",
      "Neutro",
      "Eficiente",
      "Muito Eficiente",
    ],
  },
];

export { AIDocumentQuestions, CopilotQuestions };
