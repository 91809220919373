import React from "react";

import { cn } from "@shared/editor/styles/utils";
import { LoadingDotsProps } from "./types";
import "./styles.css";

const LoadingDots = React.forwardRef<HTMLDivElement, LoadingDotsProps>(
  (props, ref) => {
    const { containerProps = {}, className = "", ...rest } = props;
    const { className: containerClassName, ...restContainerProps } =
      containerProps;

    return (
      <div
        ref={ref}
        className={cn("bouncing-loader", containerClassName)}
        {...restContainerProps}
      >
        <div className={cn(className)} {...rest} />
        <div className={cn(className)} />
        <div className={cn(className)} />
      </div>
    );
  }
);

LoadingDots.displayName = "LoadingDots";

export { LoadingDots };
